import classNames from 'classnames';
import React, { useReducer, useState } from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import mode from '../../../shared/helpers/Mode';
import { HEADER_HEIGHT } from '../constants';
import { useDomains } from '../contexts/DomainsContext';
import {
  DESKTOP,
  MOBILE,
  TABLET,
  useViewMode,
} from '../contexts/ViewModeContext';
import Resources from '../helpers/Resources';
import DesktopService from './desktop/Service';
import Header from './Header';
import MobileService from './mobile/Service';

const useStyles = createUseStyles((theme, isEmbedded) => ({
  root: {
    background: theme.palette.neutral[100],
    display: 'flex',
    flexDirection: 'column',
    fontFamily: theme.fontFamilies.sansSerif,
    fontSmoothing: 'initial',
  },
  wrap: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
    minHeight: 1,
  },
  fullPage: {
    minHeight: isEmbedded ? '100vh' : `calc(100vh - ${HEADER_HEIGHT.DESKTOP})`,
  },
  step: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    flexBasis: 1,
  },
  fullHeight: {
    minHeight: '100vh',
  },
}));

const App = () => {
  const viewMode = useViewMode();

  const isEmbedded = () => viewMode === DESKTOP || mode.isEmbedded();

  const classes = useStyles({ theme: useTheme(), isEmbedded: isEmbedded() });
  const { getVendorDomain, primary } = useDomains();

  const [expanded, setExpanded] = useState('0');

  const [{ services, categories, firstCategory }] = useReducer(
    (state, newState) => newState,
    Resources.formatServicesAndCategories(window.state.services, primary),
  );

  const [category, setCategory] = useReducer(
    (state, newState) => newState,
    firstCategory,
  );

  const selectCategory = (id) => {
    setCategory(categories.find((category) => category.id === `${id}`));
  };

  const selectService = ({ currentTarget: { dataset } }) => {
    const vendorId = parseInt(dataset.vendor, 10);
    const domain = getVendorDomain(vendorId);

    const url = `${domain}?service=${parseInt(dataset.id, 10)}${
      isEmbedded() ? '&mode=embed' : ''
    }${category ? '&category='.concat(category.id) : ''}`;

    window.location.assign(url);
  };

  const toggleDescription = (id) => setExpanded(expanded === id ? '0' : id);

  const props = {
    categories,
    category,
    expanded,
    firstCategory,
    selectCategory,
    selectService,
    services,
    toggleDescription,
  };

  return (
    <section
      className={classNames(
        classes.root,
        viewMode === DESKTOP && classes.fullHeight,
      )}
    >
      <Header />
      <section
        className={classNames(
          classes.wrap,
          viewMode === TABLET && classes.fullPage,
        )}
      >
        <main className={classes.step}>
          {viewMode === MOBILE ? (
            <MobileService {...props} />
          ) : (
            <DesktopService {...props} mode={viewMode} />
          )}
        </main>
      </section>
    </section>
  );
};

export default App;
